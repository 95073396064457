<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <b-button
        @click="click"
        class="btn btn-icon btn-light btn-sm m-2"
        :class="button"
        v-on="on"
        v-bind="attrs"
        :href="href"
        :to="to"
      >
        <span class="svg-icon svg-icon-md" :class="span">
          <!--begin::Svg Icon | path:assets/media/svg/icons/General/Settings-1.svg-->
          <inline-svg :src="src" />
          <!--end::Svg Icon-->
        </span>
      </b-button>
    </template>
    <span>{{ description }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: { src: null, variant: null, description: null,href:null,to:null },
  computed: {
    button() {
      return `btn-hover-${this.variant}`;
    },
    span() {
      return `svg-icon-${this.variant}`;
    },
  },
  mounted(){
      },
  methods:{
    click(){
      if('click' in this.$listeners)
      {
        this.$listeners.click();
      }
    }
  }
};
</script>

<style>
</style>