<template>
  <form-dialog
    :title="
      edit ? $t('edit_account') : readonly ? account.name : $t('add_account')
    "
    ref="form"
    :dialog.sync="dialog"
    :load="loadDialog"
    @close="$emit('close')"
    :readonly="readonly"
    @submit="$emit('submit')"
    width="400px"
  >
    <template v-slot:content>
      <v-row>
        <v-col cols="12" md="12" sm="12" lg="12">
          <validation-provider
            v-slot="{ errors }"
            vid="username"
            :name="$t('attributes.username')"
            :rules="{ required: true }"
          >
            <v-text-field
              :label="$t('attributes.username') + '*'"
              v-model="form.username"
              required
              :readonly="readonly"
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="12" md="12" lg="12" sm="12">
          <validation-provider
            v-slot="{ errors }"
            vid="password"
            :name="$t('attributes.password')"
            rules="required"
          >
            <v-text-field
              :label="$t('attributes.password') + '*'"
              v-model="form.password"
              required
              :readonly="readonly"
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
    </template>
  </form-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FormDialog from "../../../components/customs/form-dialog.vue";
export default {
  components: { FormDialog },
  props: {
    dialog: Boolean,
    readonly: Boolean,
    edit: Boolean,
    loadDialog: Boolean,
  },
  data() {
    return { account_types: [], types: [], files: [] };
  },
  computed: {
    ...mapGetters("Accounts", ["account",'form']),
  },
  methods: {
    ...mapActions("Accounts", ["create",'clear']),
  },
  watch: {
    dialog() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.clear();
      }
    },
  },
};
</script>

<style>
</style>