<template>
  <v-app>
    <b-card no-body class="card-custom card-stretch gutter-b">
      <b-card-header class="border-0 py-5">
        <h3
          class="card-title align-items-start flex-column"
          style="margin: auto 0"
        >
          <span class="card-label font-weight-bolder text-dark">
            {{ $t("pages.accounts") }}
          </span>
        </h3>
        <div class="card-toolbar">
          <base-button
            @click="actionHandler('store', null)"
            color="primary"
            class="font-weight-bolder font-size-sm"
            :description="$t('desctiptions.add_account')"
          >
            <i class="flaticon2-plus icon-1x" style="color: #fff"></i>
          </base-button>
        </div>
      </b-card-header>
      <v-divider class="m-0"></v-divider>
      <b-card-body>
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="12" md="10" lg="10" sm="10">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('search')"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" lg="2" sm="2" class="text-center">
                <button @click="index" class="btn btn-secondary mt-3">
                  <i class="flaticon2-reload icon-1x"></i>
                </button>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="accounts"
            :search="search"
            :loading="loadTable"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.avatar="{ item }">
              <v-img
                contain
                max-height="100"
                max-width="100"
                :src="item.avatar"
                style="margin: 5px"
              ></v-img>
            </template>
            <template v-slot:item.actions="{ item }">
              <!-- <btnIcon
                variant="success"
                src="media/svg/icons/General/Visible.svg"
                class="m-2"
                @click="actionHandler('show', item)"
                description="مشاهدة"
              ></btnIcon> -->
              <btnIcon
                description="حذف"
                variant="danger"
                src="media/svg/icons/General/Trash.svg"
                class="m-2"
                @click="deleteModal(item)"
              ></btnIcon>
            </template>
          </v-data-table>
        </v-card>
      </b-card-body>
    </b-card>
    <AddEditDialog
      :readonly="readonly"
      :edit="edit"
      @submit="submit"
      @close="dialog = false"
      :loadDialog="loadDialog"
      :dialog="dialog"
      ref="dialog"
    ></AddEditDialog>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import btnIcon from "@/components/btn/btnIcon";
import AddEditDialog from "./components/add-edit";
import BaseButton from "../../components/btn/base-button.vue";
export default {
  components: { btnIcon, AddEditDialog, BaseButton },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "#",
          align: "start",
          value: "id",
        },
        { text: this.$t("attributes.avatar"), value: "avatar" },
        { text: this.$t("attributes.name"), value: "name" },
        { text: this.$t("attributes.enter_hours"), value: "hours" },
        {
          text: this.$t("attributes.available_amount"),
          value: "amount",
        },
        { text: this.$t("actions"), value: "actions", sortable: false },
      ],
      loadTable: true,
      dialog: false,
      readonly: false,
      edit: false,
      loadDialog: false,
    };
  },
  computed: {
    ...mapGetters("Accounts", ["accounts", "account", "form"]),
  },
  watch: {},
  mounted() {
    this.index().then(() => {
      this.loadTable = false;
    });
  },
  methods: {
    submit() {
      this.loadDialog = true;
      const type = "store";
      this[type](this.form)
        .then(() => {
          this.dialog = false;
        })
        .catch(({ response }) => {
          // console.log())
          console.log(response);
          if (response.status == 422) {
            this.$refs.dialog.$refs.form.$refs.observer.setErrors(
              response.data.errors
            );
          } else {
            this.send_error(response.data.message);
          }
          this.loadDialog = false;
        });
    },
    actionHandler(type, item) {
      this.loadDialog = false;
      this.dialog = true;
      if (item) {
        this.loadDialog = true;
        Promise.all([this.show(item.id)]).then(() => {
          this.loadDialog = false;
        });
      }
    },
    ...mapActions("Accounts", [
      "index",
      "store",
      "update",
      "show",
      "clear",
      "delete",
    ]),
    deleteModal(row) {
      this.$bvModal
        .msgBoxConfirm(
          this.$createElement("div", {
            domProps: {
              innerHTML: `هل انت متأكد من حذف الحساب  <h5 class="d-inline">${row.name}</h5> ?`,
            },
          }),
          {
            title: "تأكيد الحذف",
            okTitle: "تاكيد",
            cancelTitle: "الغاء",
          }
        )
        .then((value) => {
          if (value) {
            this.loadTable = true;
            this.delete(row.id).then(() => {
              this.loadTable = false;
            });
          }
        });
    },
  },
};
</script>

<style>
</style>